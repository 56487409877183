<template>
  <div>
    <app-login-navigation />
    <app-form-register class="mt-9" />
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'

export default {
  name: 'Register',
  beforeRouteEnter (to, from, next) {
    // user already registered on this pc if cookie agreement confirmed
    // redirect from register page to login page for convenience, but only after page reload
    if (from.name === null) {
      let agree = VueCookies.get('cookieAgreement')
      if (agree && agree === 'true') {
        next('/login')
      }
    }
    next()
  }
}
</script>
